import * as React from "react";
import "./interne.css";
import Layout from "../layouts/layout";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import favicon from "../images/favico.ico";
import { StaticImage } from "gatsby-plugin-image"





const ChurigPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    const { faq } = props.pageContext


    const children = [];
    if (faq.question1 != null) {
        children.push({ question: faq.question1, answer: faq.reponse1 })
    }
    if (faq.question2 != null) {
        children.push({ question: faq.question2, answer: faq.reponse2 })
    }
    if (faq.question3 != null) {
        children.push({ question: faq.question3, answer: faq.reponse3 })
    }
    if (faq.question4 != null) {
        children.push({ question: faq.question4, answer: faq.reponse4 })
    }
    if (faq.question5 != null) {
        children.push({ question: faq.question5, answer: faq.reponse5 })
    }
    if (faq.question6 != null) {
        children.push({ question: faq.question6, answer: faq.reponse6 })
    }
    if (faq.question7 != null) {
        children.push({ question: faq.question7, answer: faq.reponse7 })
    }
    if (faq.question8 != null) {
        children.push({ question: faq.question8, answer: faq.reponse8 })
    }
    if (faq.question9 != null) {
        children.push({ question: faq.question9, answer: faq.reponse9 })
    }
    if (faq.question10 != null) {
        children.push({ question: faq.question10, answer: faq.reponse10 })
    }

    return (
        <Layout>

            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <script type="application/ld+json">
                    {JSON.stringify(children)}</script>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>

    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
    </script>
            </Helmet>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
height="0" width="0" style={{display:"none",visibility:"hidden"}} ></iframe></noscript>



            <div className="container-fluid service1 pt-5 pb-5">
                <div className="container intern-page-style">
                    <h1 className="service-title">
                        {title}
                    </h1>
                </div>
            </div>

            <div className="container mt-4 mb-4">
                <div className="row">
                    {childs && childs.map((child, i) => {
                        if (child.title == "Ballon gastrique en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                        <StaticImage src="../images/ballon-gastriqu.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Bypass gastrique en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/by-pass.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Anneau gastrique en Tunisie : Une méthode moins courante") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/anneau.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "L’opération de sleeve gastrique en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/sleeve-gas1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Implant dentaire Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/implant.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Facettes dentaires en Tunisie : Améliorez Votre Sourire à Prix Abordable") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/facette.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Blanchiment Dentaire en Tunisie : Un Sourire Éclatant à Prix Abordable") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/blanch.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Gingivectomie Tunisie : Tout ce que vous devez savoir") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/gingivectomie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Couronne dentaire Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/couronne-dentaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Biopsie testiculaire en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/testiculaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Fécondation in vitro en Tunisie (FIV)") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/vitro.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Insémination artificielle Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/artificielle.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Greffe de Poils et Cheveux en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/chev.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Greffe de Barbe en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/barbre.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Greffe et Implant de Sourcils en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/sourcil.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "LipoVaser ou Liposuccion Vaser") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lipo-vaser-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    
                        if (child.title == "Acide Hyaluronique") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/acide-hylaronique.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "La Toxine Botulique ou Bot-ox") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/botox.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Traitement Laser") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/trait-laser.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mesobotox ou Microbotox") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/mesobotox.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "PRP Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/PRP.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Rhinoplastie Médicale en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/rhinoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Les Techniques d’Amincissement") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/amincissement.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Fils Tenseurs") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/fils-tenseurs.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mésothérapie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/mesotherapie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                       
           
                        if (child.title == "Nymphoplastie en Tunisie : Réduction des lèvres vaginales") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/nymphoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Vaginoplastie Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/vaginoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Hyménoplastie en Tunisie : Restauration de l&rsquo;hymen") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/hymenoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Penoplastie en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/penoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Prothèse pénienne gonflable") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/prothese-penienne-gonflable.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Implant Pénien Semi-Rigide en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/implant-semi-rigidexx.png" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })



                    }

                </div>
            </div>
            {/* free devis block */}
            {/* <div className="container-fluid pb-3 pt-3 free-devis-bloc">
                <div className="container pt-4">

                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-6 text-center">
                            <p className="float-start text-devis-block pt-2">Demandez gratuitement votre devis</p>
                        </div>

                        <div className="col-sm-12 col-md-6 text-center">
                            <Link to="/devis/" class="d-none d-sm-none d-md-block float-end btn btn-devis-free" role="button">Devis gratuit</Link>
                            <a href="#" className="d-sm-block d-md-none btn  btn-devis-free" role="button" aria-pressed="true">DEVIS GRATUIT</a>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid  pb-5 blog-single">
                <div className="container ">
                    <div className="row">

                        {content && parse(content)}
                        {(() => {
                            const options = [];
                            if (faq.question1 != null) {
                                options.push(<h2>FAQ</h2>)
                            }
                            const options1 = [];

                            if (faq.question1 != null) {
                                options1.push(
                                    <div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                        <h3 itemprop="name">  {faq.question1}</h3>
                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                            <div itemprop="text">
                                                {faq.reponse1}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            if (faq.question2 != null) {

                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question2}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse2}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question3 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question3}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse3}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question4 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question4}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse4}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question5 != null) {

                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question5}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse5}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question6 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question6}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse6}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question7 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question7}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse7}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question8 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question8}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse8}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question9 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question9}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse9}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question10 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question10}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse10}
                                        </div>
                                    </div>
                                </div>)
                            }
                            return [options, options1];
                        })()}
                    </div>
                </div>
            </div>

        </Layout>
    )

}

export default ChurigPage
